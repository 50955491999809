<!--
 * @Author: Code-HHX
 * @Date: 2022-04-14 15:56:51
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-05-11 14:36:19
 * @Description:
-->
<template>
  <div class="container">
    <div class="content">
      <img
        class="head-store"
        src="@/assets/icons/icon_menu.png"
        @click="goStore"
      />
      <div class="head-menu-show" v-show="showMenu" @click="onClickMenuStore">
        <img src="@/assets/icons/icon_external_url.png" />
        <span>iKrusher Store</span>
      </div>
      <img class="head-logo" src="@/assets/logo/logo_omni_head.png" />
      <img
        class="head-icon-account"
        src="@/assets/icons/icon_account.png"
        @click="onClickAccount"
      />
    </div>
    <!-- Account Popup -->
    <van-popup
      class="account-popup"
      v-model="showAccountPopup"
      round
      :close-on-click-overlay="false"
    >
      <div class="popup-header">
        Account
        <img src="@/assets/icons/icon_close.png" alt="" @click="onClickClose" />
      </div>
      <div class="popup-content">
        <div class="title">Email Address</div>
        <div class="info">
          {{ accountInfo.username }}
          <!--<img src="@/assets/icons/icon_edit.png" alt="" @click="onClickEdit" />-->
        </div>
        <div class="title">Password</div>
        <div class="info">
          ●●●●●●●●
          <!--<img src="@/assets/icons/icon_edit.png" alt="" @click="onClickEdit" />-->
        </div>
      </div>
      <div class="popup-button" @click="onClickSignOut">Sign Out</div>
    </van-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import { TOKEN } from "@/config/http-json";

export default {
  name: "fixedTop",
  data() {
    return {
      showAccountPopup: false,
      accountInfo: {
        username: ""
      },
      showMenu: false,
      showFunction: false
    };
  },
  mounted() {
    let account = JSON.parse(window.localStorage.getItem("omni-user"));

    if (account) {
      this.accountInfo = account;
      this._setLoginInfo(account);
    }
  },
  watch: {
    showMenu(newVal, oldVal) {
      if (newVal) {
        document.addEventListener("click", this.click_allFunction_side, true);
        document.addEventListener("scroll", this.click_allFunction_side, true);
      } else {
        document.removeEventListener(
          "click",
          this.click_allFunction_side,
          true
        );
        document.removeEventListener(
          "scroll",
          this.click_allFunction_side,
          true
        );
      }
    }
  },
  methods: {
    ...mapActions(["_setLoginInfo"]),
    ...mapGetters(["getLoginInfo"]),
    onClickAccount() {
      this.showAccountPopup = true;
    },
    onClickClose() {
      this.showAccountPopup = false;
    },
    onClickEdit() {
      this.$toast({
        type: "success",
        message:
          "An email to reset your password has been sent to your mailbox."
      });
    },
    onClickSignOut() {
      this.$dialog
        .confirm({
          title: "Tips",
          message:
            "Are you sure you want to exit the currently logged in account?",
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
        .then(res => {
          bluetoothRepository.disconnect();
          window.localStorage.setItem(TOKEN, "");
          window.localStorage.setItem(TOKEN, null);
          this._setLoginInfo(null);
          this.showAccountPopup = false;
          this.$router.replace({name:"Login",query: {hash:this.$store.state.hash}});
        })
        .catch(() => {});
    },
    goStore() {
      this.showMenu = !this.showMenu;
    },
    onClickMenuStore() {
      if (window.openCustomUrl) {
        window.openCustomUrl("https://www.ikrusher.com/shop.html");
      } else {
        window.open("https://www.ikrusher.com/shop.html");
      }
    },
    click_allFunction_side(e) {
      if (!this.$el.contains(e.target)) {
        this.showMenu = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  overflow: initial !important;
}
.content {
  height: 95px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #b3c3c6;

  .head-icon-account {
    margin-left: 30px;
    width: 32px;
    height: 32px;
  }

  .head-store {
    margin-right: 30px;
    width: 24px;
    height: 24px;
  }

  .head-menu-show {
    background-image: url("../assets/bg/bg_menu.png");
    background-repeat: round;
    position: absolute;
    left: 24px;
    top: 70px;
    width: 200px;
    height: 68px;
    line-height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-top: 10px;
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }

    span {
      margin-top: 10px;
      font-size: 18px;
      font-family: Roboto-Bold-, Roboto-Bold;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.account-popup {
  width: 90%;

  .popup-header {
    font-size: 21px;
    font-weight: normal;
    color: #6d6e71;
    display: flex;
    align-items: center;
    padding: 20px 20px 48px 28px;

    img {
      margin-left: auto;
      width: 28px;
      height: 28px;
    }
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 28px;

    .title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: normal;
      color: #a7a9ac;
    }

    .info {
      font-size: 18px;
      font-weight: normal;
      color: #808285;
      display: flex;
      align-items: center;
      margin-bottom: 33px;

      img {
        margin-left: auto;
        margin-right: 3px;
        width: 19px;
        height: 19px;
      }
    }
  }

  .popup-button {
    margin: 62px 25px 27px 25px;
    height: 45px;
    line-height: 45px;
    background: #86a1a9;
    border-radius: 8px;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    transition: opacity 0.2s;
    -webkit-appearance: none;
  }
  .popup-button:active {
    opacity: 0.8;
    -webkit-tap-highlight-color: transparent;
  }
}
</style>
