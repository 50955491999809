<!--
 * @Author: Code-HHX
 * @Date: 2022-03-24 13:51:24
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-05-12 10:14:55
 * @Description:
-->
<template>
  <div class="container">
    <div class="header">
      <FixedTop />
    </div>
    <div class="content">
      <!--<div>版本号:{{ deviceVersion }}</div>-->
      <div v-if="!isConnected" class="bluetooth">
        <img
          src="@/assets/icons/icon_bluetooth_off.png"
          class="bluetooth-off"
        />
        <div class="bluetooth-info">
          <div class="bluetooth-info-title">Bluetooth</div>
          <div class="bluetooth-info-address">
            {{ isConnected ? "Connected" : "Not Connected" }}
          </div>
        </div>
        <div class="connect-button" @click="onClickConnectDevice">
          Connect
        </div>
      </div>
      <div v-else class="bluetooth">
        <img src="@/assets/icons/icon_bluetooth_on.png" class="bluetooth-off" />
        <div class="bluetooth-info">
          <div class="bluetooth-info-title">
            {{ deviceInfo.title }}
          </div>
          <div class="bluetooth-info-address">
            {{ isConnected ? "Connected" : "Not Connected" }}
          </div>
        </div>
        <div class="connect-button" @click="onClickDisconnectDevice">
          Disconnect
        </div>
      </div>
      <div class="settings">
        <img src="@/assets/icons/icon_settings.png" />
        <div class="title">Current Settings</div>
        <div class="settings-button" @click="onClickSettingsView">
          View
        </div>
      </div>
      <!-- <div class="settings">
        <img src="@/assets/icons/icon_external_url.png" />
        <div class="title">iKrusher Store</div>
        <div class="settings-button" @click="goStore">
          Go
        </div>
      </div> -->
      <van-tabs v-model="activeMode">
        <van-tab title="Disposable" :disabled="isDisposableDeviceConnect">
          <Disposable ref="disposable" :isConnected="isConnected" />
        </van-tab>
        <van-tab title="Battery" :disabled="isBatteryDeviceConnect">
          <Battery ref="battery" :isConnected="isConnected" />
        </van-tab>
      </van-tabs>
    </div>
    <!-- <div class="footer">
      <div class="upload-button" @click="onClickUploadFirmware">
        Upload Firmware
      </div>
    </div> -->
    <div class="footer-upload" @click="onClickUploadFirmware">
      <span>Upload Firmware</span>
    </div>

    <!-- 连接蓝牙弹窗Popup -->
    <van-popup
      class="bluetooth-popup"
      v-model="showBluetoothPopup"
      round
      :style="{ width: '88%' }"
    >
      <div class="bluetooth-header">Connect Device</div>
      <div class="bluetooth-content">
        <van-loading color="#6649C4" size="40" text-size="16px"
          >Searching...
        </van-loading>
        <div class="bluetooth-list">
          <div
            class="bluetooth-item"
            v-for="(item, index) in deviceList"
            :key="index"
          >
            <img src="@/assets/icons/icon_bluetooth_device.png" />
            {{ item.name }}
            <van-button
              class="bluetooth-connect-button"
              type="default"
              @click="onClickConnectDevice(item)"
            >
              Connect
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- Settings Popup 设备详情弹窗 -->

    <van-popup
      class="settings-popup"
      close-icon="close"
      round
      v-model="showSettingsPopup"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <CurrentSetting
        v-on:resetToHubSetting="resetToHubSetting"
        :writer-setting="writerSetting"
        :hub-setting="disposableHubSetting"
        v-on:close="onClickClose"
      />
    </van-popup>
    <div v-show="false">{{ disposableHubSetting }}</div>
  </div>
</template>

<script>
import FixedTop from "@/components/FixedTop";
import { initCanvasRatioWidthSize } from "@/util/";
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import { mapGetters, mapState } from "vuex";
import CurrentSetting from "@/components/CurrentSetting";
import Disposable from "@/components/Disposable";
import Battery from "@/components/Battery";
import { Toast } from "vant";
import log from "@/util/log";

export default {
  name: "Home",
  components: {
    Disposable,
    Battery,
    CurrentSetting,
    FixedTop
  },
  data() {
    return {
      nfcChecked: false,
      preheatChecked: false,
      showBluetoothPopup: false,

      time: 10,
      voltage: 2.1,
      deviceConnectStatus: false, //设备蓝牙连接状态
      deviceInfo: {
        title: "OMNI Hub"
      },
      activeMode: 0,
      showSettingsPopup: false,
      temperatureGraphView: {
        width: 0,
        height: 0,
        obj: null
      },
      temperatureData: [2100, 2100, 2100, 2100, 2100, 2100, 2100, 2100]
    };
  },
  watch: {
    disposableHubSetting(newValue) {
      log(`new value = ${newValue}`);
    },
    isConnected(newValue) {
      if (newValue) {
        this.activeMode = 0;
      }
    }
  },
  computed: {
    ...mapGetters("bluetooth", [
      "isConnected",
      "macAddress",
      "cartridgeFlag",
      "deviceVersion",
      "deviceList",
      "writerSetting"
    ]),
    ...mapState({
      deviceList: state => state.bluetooth.deviceList
    }),

    isDisposableDeviceConnect() {
      return this.isConnected && this.cartridgeFlag !== 48193;
    },

    isBatteryDeviceConnect() {
      return this.isConnected && this.cartridgeFlag === 48193;
    }
  },
  methods: {
    async onClickSettingsView() {
      if (!this.isConnected) {
        Toast.fail("Please Connect Device");
        return;
      }

      this.showSettingsPopup = true;
    },
    onDisableClick(event) {
      log(`不能点击了 ${event.detail.name}`);
    },
    onClickClose() {
      this.showSettingsPopup = false;
    },
    onClickNfc() {
      this.nfcChecked = !this.nfcChecked;
    },
    onClickPreheat() {
      this.preheatChecked = !this.preheatChecked;
    },
    onInputTime(value) {
      log(value);
    },
    onClickConnectDevice() {
      bluetoothRepository.startPair();
      //如果浏览器有receiveScanDevice发送蓝牙设备列表则走web方式，没有走原生
      if (Object.prototype.hasOwnProperty.call(window, "receiveScanDevice")) {
        this.showBluetoothPopup = true;
      }
      // bluetoothRepository.startPair();
    },
    goStore() {
      if (window.openCustomUrl) {
        window.openCustomUrl("https://www.ikrusher.com/shop.html");
      } else {
        window.open("https://www.ikrusher.com/shop.html");
      }
    },
    onClickDisconnectDevice() {
      bluetoothRepository.disconnect();
    },
    onClickUploadFirmware() {
      if (this.activeMode === 0) {
        this.$refs.disposable.uploadDevice();
      } else if (this.activeMode === 1) {
        this.$refs.battery.uploadDevice();
      }
    },
    resetToHubSetting() {
      if (this.activeMode === 0) {
        this.$refs.disposable.resetHub();
      } else if (this.activeMode === 1) {
        this.$refs.battery.resetHub();
      }
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
/deep/ .van-popup {
  overflow-y: hidden !important;
}

.popup-wrap {
  position: relative;
  width: 80vw;
  height: 80vh;
  overflow: scroll;

  .popup-title {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 10px;
    text-align: center;
  }
}

.container {
  height: 100%;
  background: #f1f2f2;
  position: relative;
  overflow: hidden;

  /deep/ .van-tab {
    margin: 0;
    border-bottom: 1px solid #bcbec0;
    font-weight: bold !important;
  }

  .header {
    position: absolute;
    top: 0;
    z-index: 1000;
  }

  .content {
    position: absolute;
    top: 95px;
    bottom: 1px;
    width: 100%;
    padding: 13px 0 0 0;
    overflow-y: auto;
    padding-bottom: 70px;

    .bluetooth {
      margin-top: 33px;
      display: flex;
      align-items: center;
      padding: 0 29px 0 37px;

      img {
        margin-left: 5px;
        margin-right: 6px;
        width: 22px;
        height: 42px;
      }

      .bluetooth-info {
        display: flex;
        flex-direction: column;
        margin-left: 18px;

        .bluetooth-info-title {
          font-size: 18px;
          font-weight: bold;
          color: #6d6e71;
        }

        .bluetooth-info-address {
          font-size: 16px;
          font-weight: normal;
          color: #a7a9ac;
        }
      }

      .connect-button {
        cursor: pointer;
        width: 100px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background: #86a1a9;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        margin-left: auto;
        transition: opacity 0.2s;
        -webkit-tap-highlight: transparent;
      }

      .connect-button:active {
        opacity: 0.8;
        -webkit-tap-highlight-color: transparent;
      }
    }

    .settings {
      margin-top: 26px;
      margin-bottom: 33px;
      display: flex;
      align-items: center;
      padding: 0 29px 0 37px;

      img {
        width: 32px;
        height: 32px;
      }

      .title {
        margin-left: 18px;
        font-size: 18px;
        font-weight: bold;
        color: #6d6e71;
      }

      .settings-button {
        width: 100px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background: #86a1a9;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        margin-left: auto;
        transition: opacity 0.2s;
        -webkit-appearance: none;
      }

      .settings-button:active {
        opacity: 0.8;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 144px;
    background: #ffffff;
    border-top: 1px solid #b3c3c6;

    .upload-button {
      padding: 18px 0;
      margin: 29px 25px 0 25px;
      text-align: center;
      background: #86a1a9;
      border-radius: 8px;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      transition: opacity 0.2s;
      -webkit-appearance: none;
    }

    .upload-button:active {
      opacity: 0.8;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .footer-upload {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 100%;
      margin: 0 14px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #86a1a9;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      box-shadow: 0px 0px 15px 1px #86a1a9;
      border-radius: 8px;
    }

    span:active {
      opacity: 0.8;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .settings-popup {
    width: 90%;

    .popup-header {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
      display: flex;

      align-items: center;
      padding: 20px 20px 48px 28px;

      img {
        margin-left: auto;
        width: 28px;
        height: 28px;
      }
    }

    .popup-content {
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      padding: 0 20px 0 28px;

      .title {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: normal;
        color: #a7a9ac;
      }

      .info {
        font-size: 18px;
        font-weight: normal;
        color: #808285;
        display: flex;
        align-items: center;
        margin-bottom: 33px;

        img {
          margin-left: auto;
          margin-right: 3px;
          width: 19px;
          height: 19px;
        }
      }

      .device {
        margin-bottom: 34px;

        .device-name {
          font-size: 21px;
          font-weight: bold;
          color: #6d6e71;
        }

        .device-address {
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }
      }

      .preheat {
        margin-bottom: 45px;

        .preheat-title {
          font-size: 21px;
          font-weight: bold;
          color: #6d6e71;
        }

        .preheat-time {
          margin-top: 20px;
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }

        .preheat-voltage {
          margin-top: 17px;
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }
      }

      .voltage {
        margin-bottom: 33px;

        .voltage-title {
          font-size: 21px;
          font-weight: bold;
          color: #6d6e71;
        }

        .voltage-mode {
          margin-bottom: 24px;
          font-size: 18px;
          font-weight: normal;
          color: #939598;
        }
      }
    }

    .popup-button {
      margin: 62px 25px 27px 25px;
      height: 45px;
      line-height: 45px;
      background: #86a1a9;
      border-radius: 8px;
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>
