import log from "@/util/log";

class TemperatureGraphView {
  options = {
    showLine: false,
    smooth: 0.5,
    xAxisTextSize: 12,
    xAxisTextColor: "#939598",
    xAxisDotLineWidth: 0.1, //X轴虚线宽度
    xAxisDotLineColor: "#E6E7E8", //X轴虚线颜色
    defaultPillarColor: "#A7A9AC", //Y轴颜色
    shaderPillarColor: "#ED6C0F",
    pillarWidth: 1, //Y轴线快递
    circleWidth: 20,
    circleMargin: 7,
    circleColor: "#FF8314",
    curveLevelColor: "#FF8314",
    curveBgColor: "#222222",
    customMode: { w: 22.5, h: 37.5, margin: 4 }, //按钮宽高
    topVoltageMargin: 8, //按钮宽高
    data: [],
    isCustomMode: false,
    canDrag: true
  };

  originPointData = [];
  _valuePointList = [];
  _controlPointList = [];
  _temperaturePointList = [];

  _scaleX = 0;
  _scaleY = 0;

  _secondMetrics = 0;
  _temperatureMetrics = 0;
  _temperaturePointMetrics = 0;

  _currentXValue = 0;

  _anim = null;
  isTouchInRegion = false;
  targetIndexPath = -1;
  canvasEle = null;

  customPaths = [];

  maxValue = 4200;

  constructor(canvas, width, height, ratio) {
    canvas.addEventListener("touchstart", this.touchstart.bind(this));
    canvas.addEventListener("touchmove", this.touchMove.bind(this));
    canvas.addEventListener("touchend", this.touchEnd.bind(this));
    canvas.addEventListener("touchcancel", this.touchCancel.bind(this));
    this.canvasEle = canvas;
    this._canvas = canvas.getContext("2d");
    this.options.width = width;
    this.options.height = height;
    this.ratio = ratio;
    log(`初始化的 宽：${width} 高:${height} 比例:${ratio}`);
  }

  init(options) {
    this.options = { ...this.options, ...options };
    this.originPointData = [];
    if (!options.data || options.data.length === 0) {
      return;
    }
    options.data.forEach((item, i) => {
      if (i < 8) {
        this.originPointData.push({ x: i + 1, y: item });
      }
    });

    this._canvas.font = "14px";
    this._canvas.imageSmoothingEnabled = true;
    this._temperaturePointMetrics = this._canvas.measureText(" 2.1V ");

    //计算秒数的宽度
    this._canvas.font = "13px";
    this._canvas.imageSmoothingEnabled = true;
    this._secondMetrics = this._canvas.measureText("1s");
    this._temperatureMetrics = this._canvas.measureText("  2.1V  ");

    let secondHeight = this._getTextHeight(this._secondMetrics);

    //设置scaleX， scaleY
    if (this.options.showLine) {
      this._scaleX =
        (this.options.width -
          // this._temperatureMetrics.width -
          // this._secondMetrics.width / 2 -
          this.options.customMode.w) /
        (this.originPointData.length - 1);
      this._scaleY =
        (this.options.height - this.options.customMode.h - secondHeight * 6) /
        42;
      // console.log(`scalex = ${this._scaleX} scaleY = ${this._scaleY}`);
    } else {
      this._scaleX = this.options.width / (this.originPointData.length - 1);
      this._scaleY = this.options.height / 42;
    }

    this._handleData();
  }

  _calculateValuePoint(originData, max, scaleX, scaleY) {
    this._valuePointList = [];
    originData.forEach((item, i) => {
      let offset = 0;
      let offsetY = 0;
      if (this.options.showLine) {
        // offset = this._temperatureMetrics.width;
        offset = this.options.customMode.w / 2;
        offsetY =
          this.options.customMode.h / 2 +
          this._getTextHeight(this._temperaturePointMetrics) * 2.5;
      }
      const x = i * scaleX + offset;
      const y = ((max - item.y) / 100) * scaleY + offsetY;
      // console.log(`x = ${x} y = ${y}`);
      this._valuePointList.push({ x, y });
    });
  }

  _calculateControlPoint(pointList) {
    this._controlPointList = [];
    if (pointList.length <= 1) {
      return;
    }

    const size = pointList.length;
    pointList.forEach((point, i) => {
      if (i === 0) {
        //第一项
        //添加后控制点
        const nextPoint = pointList[i + 1];
        const controlX =
          point.x + (nextPoint.x - point.x) * this.options.smooth;
        const controlY = point.y;
        this._controlPointList.push({ x: controlX, y: controlY });
      } else if (size - 1 === i) {
        //最后一项
        //添加前控制点
        const lastPoint = pointList[i - 1];
        const controlX =
          point.x - (point.x - lastPoint.x) * this.options.smooth;
        const controlY = point.y;
        this._controlPointList.push({ x: controlX, y: controlY });
      } else {
        //中间项
        const lastPoint = pointList[i - 1];
        const nextPoint = pointList[i + 1];
        const k = (nextPoint.y - lastPoint.y) / (nextPoint.x - lastPoint.x);
        const b = point.y - k * point.x;
        //添加前控制点
        const lastControlX =
          point.x - (point.x - lastPoint.x) * this.options.smooth;
        const lastControlY = k * lastControlX + b;
        this._controlPointList.push({
          x: lastControlX,
          y: lastControlY
        });
        //添加后控制点
        const nextControlX =
          point.x + (nextPoint.x - point.x) * this.options.smooth;
        let nextControlY = k * nextControlX + b;
        if (nextPoint.y === point.y) nextControlY = point.y;
        this._controlPointList.push({
          x: nextControlX,
          y: nextControlY
        });
      }
    });
  }

  _calculateTemperaturePoint() {
    this._temperaturePointList = [];
    for (let i = 10; i > 0; i--) {
      let offset = 0;
      if (this.options.showLine) {
        offset =
          this.options.customMode.h / 2 +
          this._getTextHeight(this._temperaturePointMetrics) * 2.5;
      }
      this._temperaturePointList.push({
        x: 0,
        y: 4.2 * (10 - i) * this._scaleY + offset
      });
    }
  }

  _handleData() {
    this._calculateValuePoint(
      this.originPointData,
      this.maxValue,
      this._scaleX,
      this._scaleY
    );
    this._calculateControlPoint(this._valuePointList);
    this._calculateTemperaturePoint();

    if (this.options.showLine) {
      this._currentXValue = this.options.width; //- this._temperatureMetrics.width;
    } else {
      this._currentXValue = 0;
    }
  }

  draw() {
    log("draw1");
    const canvas = this._canvas;
    const width = this.options.width;

    const firstPoint = this._valuePointList[0];
    let height = this.options.height;

    canvas.clearRect(0, 0, width, height);

    if (this.options.showLine) {
      canvas.save();
      height =
        this.options.height -
        this.options.customMode.h / 2 -
        this._getTextHeight(this._temperaturePointMetrics) * 2;
      canvas.restore();
    }

    canvas.strokeStyle = "#7D356F";
    canvas.lineWidth = 1;
    let secondHeight = this._getTextHeight(this._secondMetrics);

    canvas.save();
    canvas.beginPath();
    canvas.moveTo(firstPoint.x, height - secondHeight); //移动到初始位置
    canvas.lineTo(firstPoint.x, firstPoint.y); //移动到起始数据点
    for (let i = 0; i < (this._valuePointList.length - 1) * 2; i += 2) {
      const leftControlPoint = this._controlPointList[i];
      const rightControlPoint = this._controlPointList[i + 1];
      const rightPoint = this._valuePointList[i / 2 + 1];
      canvas.bezierCurveTo(
        leftControlPoint.x,
        leftControlPoint.y,
        rightControlPoint.x,
        rightControlPoint.y,
        rightPoint.x,
        rightPoint.y
      );
    }
    const lastPoint = this._valuePointList[this._valuePointList.length - 1];
    canvas.lineTo(lastPoint.x, height - secondHeight);
    canvas.closePath();
    canvas.clip();

    const defaultLinearGradient = canvas.createLinearGradient(
      width,
      height,
      width,
      0
    );
    if (!this.options.canDrag) {
      defaultLinearGradient.addColorStop(0, "#CCCCCC");
      defaultLinearGradient.addColorStop(1, "#58595B");
    } else {
      defaultLinearGradient.addColorStop(0, "#CBB5C6");
      defaultLinearGradient.addColorStop(1, "#7D356F");
    }
    canvas.fillStyle = defaultLinearGradient;
    canvas.lineWidth = 10;
    canvas.fillRect(0, 0, width, this.options.height);

    if (!this.options.showLine) {
      canvas.save();
      canvas.fillStyle = canvas.createLinearGradient(0, height, width, height);
      canvas.fillRect(0, 0, width, height);
      canvas.restore();
    }

    canvas.save();
    let swipeWidth = this._currentXValue;
    if (this.options.showLine) {
      swipeWidth = width;
    }
    const swipeLinearGradient = canvas.createLinearGradient(
      0,
      height,
      swipeWidth,
      height
    );
    defaultLinearGradient.addColorStop(0, "#CBB5C6");
    defaultLinearGradient.addColorStop(1, "#7D356F");
    canvas.fillStyle = swipeLinearGradient;
    canvas.fillRect(0, 0, swipeWidth, height);
    canvas.restore();
    canvas.restore();

    {
      canvas.save();
      canvas.lineWidth = 2;
      canvas.beginPath();
      canvas.moveTo(firstPoint.x, firstPoint.y); //移动到起始数据点
      for (let i = 0; i < (this._valuePointList.length - 1) * 2; i += 2) {
        const leftControlPoint = this._controlPointList[i];
        const rightControlPoint = this._controlPointList[i + 1];
        const rightPoint = this._valuePointList[i / 2 + 1];
        canvas.bezierCurveTo(
          leftControlPoint.x,
          leftControlPoint.y,
          rightControlPoint.x,
          rightControlPoint.y,
          rightPoint.x,
          rightPoint.y
        );
      }
      canvas.stroke();
      canvas.restore();
    }

    //画文字 秒数
    if (this.options.showLine) {
      let offsetY =
        this.options.customMode.h / 2 +
        this._getTextHeight(this._temperaturePointMetrics) * 2.5;
      this._valuePointList.forEach((point, i) => {
        //画树状图 默认颜色
        canvas.save();
        canvas.strokeStyle = this.options.defaultPillarColor;
        canvas.lineWidth = this.options.pillarWidth;
        canvas.beginPath();
        canvas.moveTo(point.x, offsetY);
        canvas.lineTo(point.x, height - secondHeight);
        canvas.stroke();
        canvas.restore();

        //画树状图 默认颜色
        canvas.save();
        // const pillarLinearGradient = canvas.createLinearGradient(
        //   point.x,
        //   point.y,
        //   point.x,
        //   height
        // );
        // pillarLinearGradient.addColorStop(0, "#FC6C04");
        // pillarLinearGradient.addColorStop(1, "#FFF2E6");
        // canvas.strokeStyle = pillarLinearGradient;
        // canvas.strokeStyle = '#A7A9AC';
        // canvas.lineWidth   = 1;
        // canvas.beginPath();
        // canvas.moveTo(point.x, point.y);
        // canvas.lineTo(point.x, height- secondHeight);
        // canvas.stroke();
        // canvas.restore();

        //画秒数
        canvas.save();
        canvas.font = "13px";
        canvas.textAlign = "center";
        canvas.fillStyle = "#939598";
        canvas.fillText(
          `${i + 1}s`,
          point.x,
          height +
            this.options.customMode.h / 2 +
            this._getTextHeight(this._secondMetrics) / 2
        );
        canvas.restore();
      });

      this._temperaturePointList.forEach((point, i) => {
        // console.log(i)
        // //画温度文字
        // canvas.save();
        // canvas.font = "13px";
        canvas.fillStyle = "#939598";
        canvas.textBaseline = "middle";
        // if (i == 0 || i == 5) {
        //   canvas.fillText(
        //     `${((4.2 / 10) * (10 - i)).toFixed(1)}v`,
        //     point.x,
        //     point.y
        //   ); //符号℉
        // }
        // canvas.restore();

        //画温度对应的虚线
        canvas.save();
        canvas.beginPath();
        canvas.stokeStyle = "#939598";
        canvas.setLineDash([0, 0]);
        let offset = 0;
        if (this.options.showLine) {
          offset = offsetY;
        }
        canvas.strokeStyle = this.options.xAxisDotLineColor;
        canvas.lineWidth = this.options.xAxisDotLineWidth;
        canvas.moveTo(0, point.y);
        canvas.lineTo(width - point.x - this._secondMetrics.width / 2, point.y);
        canvas.stroke();
        canvas.restore();
      });

      if (this.options.isCustomMode) {
        this.customPaths = [
          new Path2D(),
          new Path2D(),
          new Path2D(),
          new Path2D(),
          new Path2D(),
          new Path2D(),
          new Path2D(),
          new Path2D()
        ];
      }
      this._valuePointList.forEach((point, i) => {
        if (this.options.isCustomMode) {
          canvas.save();
          canvas.fillStyle = "#7D356F";
          canvas.lineWidth = 1;
          this.arcRect(
            this.customPaths[i],
            point.x - this.options.customMode.w / 2,
            point.y - this.options.customMode.h / 2,
            this.options.customMode.w,
            this.options.customMode.h,
            12
          );
          canvas.fill(this.customPaths[i]);
          canvas.clip(this.customPaths[i]);
          canvas.globalCompositeOperation = "destination-in";
          canvas.restore();

          const startX = point.x - 4;
          const endX = point.x + 4;
          const stepY =
            ((this.options.customMode.h - this.options.customMode.w) / 3) * 1.2;
          const startY = point.y - stepY;

          canvas.strokeStyle = "#CBB5C6";
          canvas.lineWidth = 2;
          for (let j = 0; j < 3; j++) {
            canvas.beginPath();
            canvas.moveTo(startX, startY + j * stepY);
            canvas.lineTo(endX, startY + j * stepY);
            canvas.stroke();
          }

          canvas.save();
          canvas.fontSize = "15px ";
          canvas.fillStyle = "#7D356F";
          // canvas.textBaseline = "middle";
          // // 填充文本
          // canvas.fillText(
          //   `${(this.originPointData[i].y / 1000).toFixed(1)}V`,
          //   point.x - this._temperaturePointMetrics.width / 2 + 2.5,
          //   point.y -
          //     this.options.customMode.h / 2 +
          //     4 -
          //     this._getTextHeight(this._temperaturePointMetrics)
          // );

          canvas.fillText(
            `${(this.originPointData[i].y / 1000).toFixed(1)}V`,
            point.x - this._temperaturePointMetrics.width / 2 + 2.5,
            this._getTextHeight(this._temperaturePointMetrics)
          );

          canvas.restore();
        } else {
          canvas.save();
          canvas.beginPath();
          canvas.fillStyle = this.options.circleColor;
          canvas.arc(point.x, point.y, 4, 0, 2 * Math.PI);
          canvas.fill();
          canvas.restore();

          canvas.save();
          canvas.beginPath();
          canvas.font = "12px";
          canvas.fillStyle = "white";
          canvas.arc(point.x, point.y, 2, 0, 2 * Math.PI);
          canvas.fill();
          canvas.restore();
        }
      });
    }
  }

  startSwipe() {
    const animRefreshCount = 6000 / (1000 / 60); //6秒内刷新的次数
    const _this = this;
    let currentAnimRefreshTimes = 0;
    const viewWidth = this.options.width;

    function swipeChanged() {
      const percent = currentAnimRefreshTimes / animRefreshCount;
      _this._currentXValue = viewWidth * percent;
      _this.draw();
      if (currentAnimRefreshTimes <= animRefreshCount) {
        ++currentAnimRefreshTimes;
        // console.log(`percent : ${currentAnimRefreshTimes}     ---  ${percent}  ------ ${_this._currentXValue}`)
        _this._anim = requestAnimationFrame(swipeChanged);
      }
    }

    _this._anim = requestAnimationFrame(swipeChanged);
  }

  stopSwipe() {
    this._currentXValue = 0;
    cancelAnimationFrame(this._anim);
  }

  _getTextHeight(metrics) {
    return metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
  }

  arcRect(path, x, y, w, h, r) {
    path.moveTo(x + r, y);
    path.lineTo(x + w - r, y);
    path.quadraticCurveTo(x + w, y, x + w, y + r);
    path.lineTo(x + w, y + h - r);
    path.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
    path.lineTo(x + r, y + h);
    path.quadraticCurveTo(x, y + h, x, y + h - r);
    path.lineTo(x, y + r);
    path.quadraticCurveTo(x, y, x + r, y);
    path.closePath();
  }

  touchstart(event) {
    if (!this.options.isCustomMode || !this.options.canDrag) return;
    event.preventDefault();
    const _that = this;
    log(`touch start ${event}`);
    if (event.targetTouches.length === 1) {
      const targetTouch = event.targetTouches[0];
      const clientX =
        targetTouch.clientX - event.target.getBoundingClientRect().x;
      const clientY =
        targetTouch.clientY - event.target.getBoundingClientRect().y;
      // console.log(`x = ${clientX} y = ${clientY} ${this._valuePointList[0].x} ${this._valuePointList[0].y}`)
      _that.customPaths.forEach((item, i) => {
        // console.log(`in path ${i} -> ${this._canvas.isPointInPath(item, clientX * this.ratio, clientY * this.ratio)}`)
        if (
          this._canvas.isPointInPath(
            item,
            clientX * this.ratio,
            clientY * this.ratio
          )
        ) {
          this.isTouchInRegion = true;
          this.targetIndexPath = i;
        }
      });
    }
  }

  touchMove(event) {
    if (!this.options.isCustomMode) return;
    // console.log('touch move')
    if (!this.isTouchInRegion) return;
    if (event.targetTouches.length === 1) {
      event.preventDefault();
      const targetTouch = event.targetTouches[0];

      const currentY =
        targetTouch.clientY - event.target.getBoundingClientRect().y;
      let temperature = this.maxValue - (currentY / this._scaleY) * 100;
      if (temperature >= this.maxValue) {
        temperature = this.maxValue;
      } else if (temperature <= 0) {
        temperature = 0;
      }

      // console.log(`改变的温度为 = ${temperature} ${this.targetIndexPath}`)

      const targetTemperature = this.originPointData[this.targetIndexPath];
      targetTemperature.x = this.targetIndexPath;
      targetTemperature.y = temperature;

      this._handleData();
      this.draw();
    }
  }

  touchEnd(event) {
    if (!this.options.isCustomMode) return;
    log("touch end");
    this.isTouchInRegion = false;
  }

  touchCancel() {
    if (!this.options.isCustomMode) return;
    log("touch cancel");
    this.isTouchInRegion = false;
  }
}

export default TemperatureGraphView;
