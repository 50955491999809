<template>
  <div>
    <div class="voltage-curve">
      <div class="title">Voltage Curve</div>
      <!-- 温度曲线组件插入位置 -->
      <div class="curve" ref="curveContainer">
        <canvas ref="temperatureCanvas"></canvas>
      </div>
      <!--<div class="curve-mode">-->
      <!--<div-->
      <!--v-for="(curve, index) in localDisposableCurve"-->
      <!--:key="index"-->
      <!--class="curve-button"-->
      <!--:class="select === index ? 'curve-button-active' : ''"-->
      <!--@click="changeLocalCurve(curve, index)"-->
      <!--&gt;-->
      <!--Curve {{ index + 1 }}-->
      <!--</div>-->
      <!--</div>-->

      <div class="history-mode">
        <div
          class="history-button"
          :class="select === index ? 'history-button-active' : ''"
          v-for="(curve, index) in localDisposableCurve"
          :key="index"
          v-press="
            () => {
              handleClickLong(index);
            }
          "
          @click="changeLocalCurve(curve, index)"
        >
          {{ curve.diyName }}
        </div>
      </div>

      <div
        class="sub-title"
        v-show="isConnected && disposableVoltageList.length > 0"
      >
        Recommended:
      </div>
      <div class="recommended" v-show="isConnected">
        <div
          class="recommended-button"
          :class="select === index + 4 ? 'recommended-button-active' : ''"
          v-for="(recommend, index) in disposableVoltageList"
          :key="recommend.recommendedCurveName"
          @click="changeVoltageCurve(recommend, index)"
        >
          {{ recommend.recommendedCurveName }}
        </div>
      </div>
    </div>
    <div class="nfc" @click="onClickNfc">
      <div class="top">
        NFC Settings
        <div :class="[hubSetting.isSupportNfc ? `checked` : `unchecked`]">
          <div class="inner"></div>
        </div>
      </div>
    </div>
    <div
      class="preheat"
      :class="isSupportPreheat ? 'preheat-open' : ''"
      @click="onClickPreheat"
    >
      <div class="top">
        Preheat
        <div :class="[isSupportPreheat ? `checked` : `unchecked`]">
          <div class="inner"></div>
        </div>
        <!--<div v-show="isSupportPreheat" class="checked">-->
        <!--<div class="inner"></div>-->
        <!--</div>-->
      </div>
      <div class="time" ref="time" v-show="isSupportPreheat">
        <div class="title">Time</div>
        <div
          class="time-show"
          ref="timeShow"
          :style="'margin-left:' + timeShowMargin + 'px'"
        >
          <div class="time-number">
            {{ (hubSetting.preheatTime / 1000).toFixed(1) }}s
          </div>
          <div class="time-spot"></div>
        </div>
        <van-slider
          class="time-slider"
          :style="`width:` + timeSliderWidth + `px`"
          v-model="hubSetting.preheatTime"
          :min="500"
          :max="20000"
          :step="500"
          bar-height="2px"
          active-color="#A055A0"
          inactive-color="#E6E7E8"
          @input="onInputTime"
        >
        </van-slider>
      </div>
      <div class="voltage" ref="voltage" v-show="isSupportPreheat">
        <div class="title">Voltage</div>
        <div
          class="voltage-show"
          ref="voltageShow"
          :style="'margin-left:' + voltageShowMargin + 'px'"
        >
          <div class="voltage-number">
            {{ (hubSetting.preheatVoltage / 1000).toFixed(1) }}V
          </div>
          <div class="voltage-spot"></div>
        </div>
        <van-slider
          class="voltage-slider"
          :style="`width:` + voltageSliderWidth + `px`"
          v-model="hubSetting.preheatVoltage"
          :min="100"
          :max="4200"
          :step="100"
          bar-height="2px"
          active-color="#A055A0"
          inactive-color="#E6E7E8"
          @input="onInputVoltage"
        />
      </div>
    </div>
    <div v-show="false">{{ resetHubSetting }}</div>
    <van-popup
      class="rename-popup"
      close-icon="close"
      round
      v-model="showRenamePopup"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <div class="popup-header">Rename</div>
      <div class="popup-content">
        <div class="content-title">Please input the button name</div>
        <input
          ref="rename"
          class="popup-rename"
          type="text"
          v-model="rename"
          placeholder="Enter new name"
          maxlength="10"
        />
      </div>
      <div class="popup-footer">
        <button class="footer-cancel" @click="onClickCancel">
          Cancel
        </button>
        <button class="footer-confirm" @click="onClickConfirm">
          Confirm
        </button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { initCanvasRatioWidthSize } from "@/util/";
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import TemperatureGraphView from "@/components/TemperatureGraphView";
import { WriterSetting } from "@/bluetooth/BluetoothData";
import { mapGetters, mapState } from "vuex";
import device from "@/api/device";
import store from "@/store";
import { LocalCurve } from "../bluetooth/BluetoothData";
import log from "@/util/log";
import press from "@/util/press";

import { Toast } from "vant";

export default {
  directives: {
    press
  },
  name: "Disposable",
  props: {
    isConnected: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      hubSetting: new WriterSetting(),
      isFirst: true,
      timeShowMargin: 0,
      timeSliderWidth: 0,
      voltageShowMargin: 0,
      voltageSliderWidth: 0,
      isSupportPreheat: false,
      select: -1,
      localSelect: -1,
      temperatureGraphView: {
        width: 0,
        height: 0,
        obj: null
      },
      temperatureData: [2100, 2100, 2100, 2100, 2100, 2100, 2100, 2100],
      showRenamePopup: false,
      rename: "",
      renameIndex: -1
    };
  },
  methods: {
    //NFC开关点击
    onClickNfc() {
      this.hubSetting.isSupportNfc = !this.hubSetting.isSupportNfc;
    },
    //预热开关点击
    onClickPreheat() {
      // this.disposableHubSetting.isSupportPreheat = !this.disposableHubSetting.isSupportPreheat
      this.isSupportPreheat = !this.isSupportPreheat;
    },
    //计算预热秒数滑动的偏移量
    onInputTime(value) {
      if (this._isMounted) {
        let percent = (value - 500) / 19500;
        let timeWidth = this.$refs.time.offsetWidth;
        let timeShowWidth = this.$refs.timeShow.offsetWidth;
        this.timeShowMargin = (timeWidth - timeShowWidth) * percent;
        log(`value = ${value} percent= ${percent}`);
      }
    },
    //计算电压滑动的偏移量
    onInputVoltage(value) {
      if (this._isMounted) {
        let percent = (value - 100) / 4100;
        let voltageWidth = this.$refs.voltage.offsetWidth;
        let voltageShowWidth = this.$refs.voltageShow.offsetWidth;
        this.voltageShowMargin = (voltageWidth - voltageShowWidth) * percent;
      }
    },
    //本地曲线选择后 更新曲线
    changeLocalCurve(curve, index) {
      if (this.select === index) {
        return;
      }
      //更改前保存一下之前的
      if (this.localSelect !== -1 && this.localSelect < 4) {
        let currentPointData = this.temperatureGraphView.obj.originPointData;
        this.localDisposableCurve[
          this.localSelect
        ].diyVoltage = currentPointData.map((item, index) => {
          return parseFloat((item.y / 1000).toFixed(1)) * 1000;
        });
        //这里更新到本地存储
        store.commit("setLocalDisposableVoltage", this.localDisposableCurve);
      }
      this.localSelect = index;
      this.select = index;
      this.hubSetting.diyVoltage = curve.diyVoltage;
      const temperatureViewObj = this.temperatureGraphView.obj;
      temperatureViewObj.init({
        showLine: true,
        data: this.hubSetting.diyVoltage,
        isCustomMode: true
      });
      temperatureViewObj.draw();
    },
    //服务器曲线选择后 更新曲线
    changeVoltageCurve(recommend, index) {
      //更改前保存一下之前的
      if (this.localSelect !== -1) {
        let currentPointData = this.temperatureGraphView.obj.originPointData;
        this.localDisposableCurve[
          this.localSelect
        ].diyVoltage = currentPointData.map((item, index) => {
          return parseFloat((item.y / 1000).toFixed(1)) * 1000;
        });
        //这里更新到本地存储
        store.commit("setLocalDisposableVoltage", this.localDisposableCurve);
        this.localSelect = -1;
      }
      this.select = index + 4; //这个4随便写的，用来标记 大于3代表要上传到服务器
      this.hubSetting.diyVoltage = recommend.recommendedCurveTemperature
        .replace(" ", "")
        .split(",")
        .map(item => parseInt(item));
      const temperatureViewObj = this.temperatureGraphView.obj;
      temperatureViewObj.init({
        showLine: true,
        data: this.hubSetting.diyVoltage,
        isCustomMode: true
      });
      temperatureViewObj.draw();
    },

    //上传设置到服务器和设备
    uploadDevice() {
      if (!this.isConnected) {
        Toast.fail("Please connect your device via Bluetooth");
        return;
      }

      if (this.select === -1) {
        Toast.fail("Please select curve before upload");
        return;
      }
      //上传设置到服务器和设备
      //取出温度
      let currentPointData = this.temperatureGraphView.obj.originPointData;
      this.hubSetting.diyVoltage = currentPointData.map((item, index) => {
        return parseFloat((item.y / 1000).toFixed(1)) * 1000;
      });
      log(`setting 参数: `);
      this.hubSetting.toString();
      if (this.select < 4 && this.select > 0) {
        this.localDisposableCurve[
          this.select
        ].diyVoltage = this.hubSetting.diyVoltage;
        //这里更新到本地存储
        store.commit("setLocalDisposableVoltage", this.localDisposableCurve);
      }
      try {
        //写入到蓝牙写入器
        bluetoothRepository.writeToWriter(this.hubSetting);
        store.commit("setDisposableSetting", this.hubSetting);
        Toast.success("Successfully");
      } catch (e) {
        Toast.fail("Fail");
        return;
      }

      //选择的是服务器的来调节,所以要上传到服务器
      // if (this.select > 3) {
      let hw = "";
      let fw = "";
      // let deviceVersion = this.deviceVersion.split("&");
      // //解析设备的版本信息
      // if (deviceVersion.length === 2) {
      //   hw = parseInt(deviceVersion[0].replace("HW", ""), 16);
      //   fw = parseInt(deviceVersion[1].replace("FW", ""));
      // }
      const resp = device.uploadConfig(
        this.cartridgeFlag,
        this.macAddress,
        this.deviceVersion,
        "iOS&1.0.0",
        this.hubSetting
      );
      //TODO 更新本地的服务器曲线缓存
      // }
    },
    resetHub() {
      this.hubSetting = new WriterSetting(this.resetHubSetting);
      const diyVoltage = this.hubSetting.diyVoltage;
      this.isSupportPreheat = this.hubSetting.isSupportPreheat;
      const temperatureViewObj = this.temperatureGraphView.obj;
      temperatureViewObj.init({
        showLine: true,
        data: diyVoltage,
        isCustomMode: true
      });
      temperatureViewObj.draw();
    },
    handleClickLong(index) {
      this.renameIndex = index;
      log(`long press index = ${index}`);
      this.rename = this.localDisposableCurve[this.renameIndex].diyName;
      this.showRenamePopup = true;
    },
    onClickCancel() {
      this.rename = "";
      this.showRenamePopup = false;
    },
    onClickConfirm() {
      if (this.rename == "") {
        Toast.fail("Name can not empty");
        return;
      }
      //保存曲线和名字
      let currentPointData = this.temperatureGraphView.obj.originPointData;
      this.localDisposableCurve[
        this.renameIndex
      ].diyVoltage = currentPointData.map((item, index) => {
        return parseFloat((item.y / 1000).toFixed(1)) * 1000;
      });
      this.localDisposableCurve[this.renameIndex].diyName = this.rename;
      //这里更新到本地存储
      store.commit("setLocalDisposableVoltage", this.localDisposableCurve);

      //关闭弹窗
      this.onClickCancel();
    }
  },
  computed: {
    ...mapState([
      "disposableVoltageList",
      "localDisposableCurve",
      "disposableHubSetting",
      "resetHubSetting"
    ]),
    ...mapState("bluetooth", ["cartridgeFlag", "macAddress", "deviceVersion"])
  },
  watch: {
    isSupportPreheat(newValue, oldValue) {
      this.hubSetting.isSupportPreheat = newValue;
      if (newValue) {
        const preheatPercent = (this.hubSetting.preheatTime - 500) / 19500;
        const voltagePercent = (this.hubSetting.preheatVoltage - 100) / 4100;
        this.$nextTick(() => {
          let timeWidth = this.$refs.time.offsetWidth;
          let timeShowWidth = this.$refs.timeShow.offsetWidth;
          let voltageWidth = this.$refs.voltage.offsetWidth;
          let voltageShowWidth = this.$refs.voltageShow.offsetWidth;
          this.timeSliderWidth = timeWidth - timeShowWidth;
          this.voltageSliderWidth = voltageWidth - voltageShowWidth;
          this.timeShowMargin = (timeWidth - timeShowWidth) * preheatPercent;
          this.voltageShowMargin =
            (voltageWidth - voltageShowWidth) * voltagePercent;
        });
      }
    },
    disposableHubSetting(newValue, oldValue) {
      if (this.isFirst) {
        this.hubSetting = new WriterSetting(newValue);
        this.isFirst = false;
      }
      const diyVoltage = newValue.diyVoltage;
      this.isSupportPreheat = newValue.isSupportPreheat;
      const temperatureViewObj = this.temperatureGraphView.obj;
      temperatureViewObj.init({
        showLine: true,
        data: diyVoltage,
        isCustomMode: true
      });
      temperatureViewObj.draw();
    }
    // resetHubSetting(newValue, oldValue) {
    //   this.hubSetting = newValue;
    //   const diyVoltage = newValue.diyVoltage;
    //   this.isSupportPreheat = newValue.isSupportPreheat;
    //   const temperatureViewObj = this.temperatureGraphView.obj;
    //   temperatureViewObj.init({
    //     showLine: true,
    //     data: diyVoltage,
    //     isCustomMode: true
    //   });
    //   temperatureViewObj.draw();
    // }
  },
  mounted() {
    store.dispatch("fetchLocalDisposableVoltage");
    const canvas = this.$refs.temperatureCanvas;
    const curveContainer = this.$refs.curveContainer;
    this.temperatureGraphView.width = curveContainer.offsetWidth;
    this.temperatureGraphView.height = curveContainer.offsetHeight;
    log(`time show width = ${curveContainer.timeShowWidth}`);
    // console.log(curveContainer.offsetWidth);
    // console.log(curveContainer.offsetHeight);

    if (canvas.getContext) {
      const canvasObj = initCanvasRatioWidthSize(
        canvas,
        curveContainer.offsetWidth,
        curveContainer.offsetHeight
      );
      const viewWidth = canvasObj.width;
      const viewHeight = canvasObj.height;
      const ratio = canvasObj.ratio;
      this.temperatureGraphView.obj = new TemperatureGraphView(
        canvas,
        viewWidth,
        viewHeight,
        ratio
      );
      const temperatureViewObj = this.temperatureGraphView.obj;
      this.$nextTick(() => {
        temperatureViewObj.init({
          showLine: true,
          data: this.temperatureData,
          isCustomMode: true
        });
        temperatureViewObj.draw();
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  position: absolute;
  top: 95px;
  bottom: 144px;
  width: 100%;
  padding: 13px 0 0 0;
  overflow-y: auto;

  .unchecked {
    width: 26px;
    height: 26px;
    border: 2px solid #d1d3d4;
    border-radius: 50%;
    margin-left: auto;
    display: flex;
    align-items: center;

    .inner {
      width: 20px;
      height: 20px;
      background: #e6e7e8;
      border-radius: 50%;
      margin: 0 auto;
    }
  }

  .checked {
    width: 26px;
    height: 26px;
    border: 2px solid #6d6e71;
    border-radius: 50%;
    margin-left: auto;
    display: flex;
    align-items: center;
    background: #a1c4ce;

    .inner {
      margin: 0 auto;
      width: 20px;
      height: 20px;
      background: #3290a0;
      border-radius: 50%;
    }
  }

  .nfc {
    margin: 12px 13px 0 13px;
    height: 60px;
    line-height: 60px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 1px rgba(132, 132, 132, 0.25098039215686274);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 0 24px;
    font-size: 18px;
    font-weight: bold;
    color: #6d6e71;

    .top {
      display: flex;
      align-items: center;
    }
  }

  .preheat {
    margin: 12px 13px 23px 13px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 1px rgba(132, 132, 132, 0.25098039215686274);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 18px;
    font-weight: bold;
    color: #6d6e71;
    display: flex;
    flex-direction: column;

    .top {
      padding: 0 24px;
      margin-top: 15px;
      display: flex;
      align-items: center;
    }

    .time {
      padding-top: 15px;

      .title {
        padding-left: 24px;
        font-size: 13px;
        font-weight: normal;
        color: #939598;
      }

      .time-show {
        width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .time-number {
          margin-top: 10px;
          width: 80px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #e6e7e8;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          font-size: 18px;
          font-weight: normal;
          color: #58595b;
        }

        .time-spot {
          margin: 3px 0 15px 0px;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: #a055a0;
          opacity: 1;
        }
      }

      .time-slider {
        margin: 0 auto;
      }
    }
  }

  .voltage {
    padding-top: 25px;

    .title {
      padding-left: 24px;
      font-size: 13px;
      font-weight: normal;
      color: #939598;
    }

    .voltage-show {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .voltage-number {
        margin-top: 10px;
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #e6e7e8;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        font-size: 18px;
        font-weight: normal;
        color: #58595b;
      }

      .voltage-spot {
        margin: 3px 0 15px 0px;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background: #a055a0;
        opacity: 1;
      }
    }

    .voltage-slider {
      width: 78%;
      margin: 0 auto;
    }
  }

  .voltage-curve {
    margin: 23px 13px 13px 13px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 1px rgba(132, 132, 132, 0.25098039215686274);
    border-radius: 8px 8px 8px 8px;
    display: flex;
    flex-direction: column;

    .title {
      padding: 0 25px;
      margin: 14px 0 16px 0;
      font-size: 18px;
      font-weight: bold;
      color: #6d6e71;
      text-align: center;
    }

    .curve {
      height: 190px;
      margin: 0 17px;
    }

    .curve-mode {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 22px;
      margin-bottom: 18px;

      .curve-button {
        cursor: pointer;
        padding: 0 16px;
        height: 35px;
        line-height: 35px;
        background: #3290a0;
        box-shadow: 2px 2px 2px 1px rgba(110, 114, 114, 0.5019607843137255);
        border-radius: 4px;
        font-size: 18px;
        font-weight: normal;
        color: #ffffff;
      }

      .curve-button-active {
        color: #3290a0;
        background: #d5efee !important;
      }
    }

    .history-mode {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 22px;
      margin-bottom: 18px;

      .history-button {
        width: 73px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #3290a0;
        box-shadow: 2px 2px 2px 1px rgba(110, 114, 114, 0.5019607843137255);
        border-radius: 4px;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .history-button-active {
        background: #d5efee !important;
        color: #3290a0;
      }
    }

    .sub-title {
      padding: 0 23px 5px 23px;
      font-size: 16px;
      font-weight: normal;
      color: #a7a9ac;
    }

    .recommended {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 22px;
      margin-bottom: 17px;

      .recommended-button {
        padding: 0;
        line-height: 17px;
        text-align: center;
        width: 145px;
        height: 35px;
        line-height: 35px;
        cursor: pointer;
        background: #3290a0;
        box-shadow: 2px 2px 2px 1px rgba(110, 114, 114, 0.5019607843137255);
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }

      .recommended-button-active {
        background: #d5efee !important;
        color: #3290a0 !important;
      }

      .recommended-battery-button {
        margin: 0 auto;
        width: 175px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        background: #3290a0;
        box-shadow: 2px 2px 2px 1px rgba(110, 114, 114, 0.5019607843137255);
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 7px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .preheat-open {
    height: 285px;
  }
}

.custom-button {
  width: 40px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  border-radius: 100px;
}

.rename-popup {
  width: 90%;

  .popup-header {
    display: flex;
    justify-content: center;
    margin: 20px 0 40px 0;
    font-size: 21px;
    font-weight: bold;
    color: #6d6e71;
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: normal;
    color: #231f20;
    padding: 0 17px;

    .popup-rename {
      margin-top: 10px;
    }
  }

  .popup-footer {
    display: flex;
    padding: 0 17px;
    margin: 35px 0 20px 0;

    button {
      width: 100%;
    }

    .footer-cancel {
      margin-right: 5px;
      background: #e8eff2;
      border-radius: 5px 5px 5px 5px;
      color: #939598;
    }
    .footer-confirm {
      margin-left: 5px;
    }
  }
}
</style>
